<template>
	<span>
		{{timeago}}
	</span>
</template>
<script type="text/javascript">
	export default {
		props: {
			datetime: String,
		},
		computed: {
			timeago(){
				if (!this.datetime) {return ''}
				const diff = new Date() - new Date(this.datetime)

				const second = 1000
				const minute = second * 60
				const hour = minute * 60
				const day = hour * 24
				const month = day * 30
				const year = day * 365

				if (diff < second * 2) {
					return "Hace un momento";
				} else if (diff < minute) {
					return `Hace ${Math.floor(diff / second)} segundos`
				} else if (diff < minute * 2) {
					return `Hace 1 minuto`
				} else if (diff < hour) {
					return `Hace ${Math.floor(diff / minute)} minutos`
				} else if (diff < hour * 2) {
					return `Hace 1 hora`
				} else if (diff < day) {
					return `Hace ${Math.floor(diff / hour)} horas`
				} else if (diff < day * 2) {
					return `Hace 1 día`
				} else if (diff < month) {
					return `Hace ${Math.floor(diff / day)} días`
				} else if (diff < month * 2) {
					return `Hace 1 mes`
				} else if (diff < year) {
					return `Hace ${Math.floor(diff / month)} meses`
				} else if (diff < year * 2) {
					return "Hace 1 año"
				} else {
					return `Hace ${Math.floor(diff / year)} años`
				}
			}
		}
	}
</script>